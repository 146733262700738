<template>
    <div class="pageContol">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                    <a href="javascript:;" class="cur-a">首页</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="block-item block-item1">
                    <div class="title">我的招聘</div>
                    <div v-show="myRecruitmentList.length" class="echatrs" ref="echarts"></div>
                    <div v-show="!myRecruitmentList.length" class="empty">
                        <el-empty description="暂无数据"></el-empty>
                    </div>
                </div>

                <div class="block-item2 block-item">
                    <div class="top">
                        <div class="title">企业培训</div>
                        <div class="more" @click="toEntertrain">更多<i class="el-icon-arrow-right"></i></div>
                    </div>
                    <div v-if="getEnterRrainList.length" class="list">
                        <div class="list-item" @click="toEntertrain" v-for="item in getEnterRrainList" :key="item.projectId">
                            <div class="list-item-left">
                                <div class="list-item-top">
                                    <div class="title">{{item.projectName}}</div>
                                    <div 
                                      class="state"
                                      :style="item.projectState=='10'?notStartStyle:item.projectState=='20'?doingStyle:endStyle"
                                      >
                                      {{item.projectState=="10"?"未开始":item.projectState=="20"?"进行中":"已结束"}}
                                    </div>
                                </div>
                                <div class="detail">
                                    <div class="progress">培训进度<span>{{item.progress}}%</span></div>
                                    <div class="total">共 <span>{{item.projectPeople}}</span> 名学员学习，累计学习时长 <span>{{item.totalTimeSum}}</span> 分钟</div>
                                </div>
                            </div>
                            <div class="list-item-right">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                    <div v-else class="empty">
                        <el-empty description="暂无数据"></el-empty>
                    </div>
                </div>

                <div class="block-item3 block-item">
                    <div class="title">快捷入口</div>
                    <div class="list">
                        <div class="list-item" @click="fastEntry(item.routePath)" v-for="(item,index) in fastEntryList" :key="index">
                            <div class="img-wrap">
                                <img :src="item.imgUrl" alt="">
                            </div>
                            <div class="subtitle">{{item.title}}</div>
                        </div>
                    </div>
                </div>

                <div class="block-item4 block-item">
                    <div class="title">劳动法规</div>
                    <div v-if="laborList.length" class="list">
                        <div class="list-item" v-for="(item,index) in laborList" :key="index" @click="goLaborPage(item.lawsUrl)">
                            <div class="className">{{item.lawsName}}</div>
                            <div class="date">{{item.formatTime}}</div>
                        </div>
                    </div>
                    <div v-else class="empty">
                        <el-empty description="暂无数据"></el-empty>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"HRHome",
        data(){
            return{
                // 图标实例
                chart:null,
                // 图标数据
                chartOption:{
                    // 提示文字
                    tooltip:{
                        trigger:"axis",
                    },
                    // 图例设置
                    legend:{
                        // 图例宽度
                        itemWidth:20,
                        // 图例高度
                        itemHeight:2,
                        // 图例文字颜色
                        textStyle:{
                            color:"#999999"
                        },
                        // 图例位置
                        right:0,
                        bottom:0
                    },
                    // 横轴
                    xAxis: {
                        type: 'value',
                        // 不显示分割线
                        splitLine:{
                            show:false
                        },
                        // 不要出现小数
                        minInterval:1
                    },
                    // 纵轴
                    yAxis:[
                        {
                            type: 'category',
                            // 坐标轴刻度和文字
                            show:false,
                            data:[]
                        },
                        {
                            type:"category",
                            // 数据
                            data:[],
                            // 不显示坐标线
                            axisLine:{
                                show:false
                            },
                            // 不显示刻度
                            axisTick:{
                                show:false
                            },
                            axisLabel:{
                                // 坐标文字与轴的距离
                                margin:"190",
                                textStyle:{
                                    // 坐标文字对齐方式
                                    align:"right",
                                }
                            }
                        }
                    ],
                    // echart大小
                    grid:{
                        top:0,
                        left:10,
                        bottom:54,
                        right:200
                    },
                    // 数据
                    series: [
                        {
                            name:"查看人数",
                            // 数据
                            data: [],
                            // 柱状图类型
                            type: 'bar',
                            // 柱状图宽度
                            barWidth:"18px",
                            // 柱的样式
                            itemStyle:{
                                // 柱颜色
                                color:"#E9F1FF",
                                // 鼠标悬停颜色
                                emphasis:{ color:"#DCE9FF"},
                            },
                            // 层级
                            z:"-1",
                            
                        },
                        {
                            name:"投递人数",
                            // 数据
                            data: [],
                            // 柱状图类型
                            type: 'bar',
                            // 柱状图宽度
                            barWidth:"18px",
                            // 柱的颜色
                            itemStyle:{
                                color:"#93BBFF"
                            },
                            // 偏移
                            barGap: "-100%",
                        }
                    ]
                },
                // 企业培训列表数据
                getEnterRrainList:[],
                // 未开始样式
                notStartStyle:{
                    "backgroundColor": "#99999933",
                    "color":"#999999"
                },
                // 进行中样式
                doingStyle:{
                    "backgroundColor":"#48db9933",
                    "color":"#48DB99"
                },
                // 已结束样式
                endStyle:{
                    "backgroundColor": "#ff3c0033",
                    "color":"#FF3C00"
                },
                // 劳动法规数据
                laborList:[],
                // 我的招聘数据
                myRecruitmentList:[],
                // 快捷入口数据
                fastEntryList:[
                    {
                        title:"招聘登记",
                        imgUrl:require("../../assets/humanResources/fast-entry1.png"),
                        routePath:"/azhr/humanResources/postRegistration/advertisementList"
                    },
                    {
                        title:"简历查看",
                        imgUrl:require("../../assets/humanResources/fast-entry2.png"),
                        routePath:"/azhr/humanResources/resumeLibrary/resumeList"
                    },
                    {
                        title:"课程商城",
                        imgUrl:require("../../assets/humanResources/fast-entry3.png")
                    },
                    {
                        title:"创建培训",
                        imgUrl:require("../../assets/humanResources/fast-entry4.png")
                    },
                    {
                        title:"创建题库",
                        imgUrl:require("../../assets/humanResources/fast-entry5.png")
                    },
                    {
                        title:"培训详情",
                        imgUrl:require("../../assets/humanResources/fast-entry6.png"),
                        routePath:"/azhr/humanResources/trainingManagement/classListCtr"
                    }
                ]
            }
        },
        methods:{
            // 初始化数据
            initData(){
                this.getEnterRrain();
                this.getLabor();
                this.getMyRecruitment();
            },
            // 获取企业培训板块数据
            getEnterRrain(){
                this.$post("/hr/home/trainList",{}).then(res=>{
                    this.getEnterRrainList=res.data||[];
                }).catch(e=>{
                    console.error("获取企业培训板块数据出错",e);
                });
            },
            // 点击企业培训跳页
            toEntertrain(){
                this.$router.push("/azhr/humanResources/trainingManagement/classListCtr");
            },
            // 获取劳动法规数据
            getLabor(){
                this.$post("/hr/home/laborLawsList",{}).then(res=>{
                    const arr=res.data||[];
                    // 处理时间
                    res.data.forEach(item=>{
                        if(item.createTime){
                            item.formatTime=item.createTime.slice(0,10).replaceAll("/","-");;
                        }
                    });
                    this.laborList=arr;
                }).catch(e=>{
                    console.error("获取劳动法规板块数据出错",e);
                });
            },
            // 点击法规跳页
            goLaborPage(url){
                if(url){
                    window.open(url);
                }
            },
            // 获取我的招聘数据
            getMyRecruitment(){
                this.$post("/hr/home/myRecruitment",{}).then(res=>{
                    this.myRecruitmentList=res.data || [];
                    // 整理坐标轴数据
                    const axisData=this.myRecruitmentList.reduce((pre,cur)=>{
                        pre.push(cur.title);
                        return pre;
                    },[]);
                    // 整理查看人数数据
                    const viewNumData=this.myRecruitmentList.reduce((pre,cur)=>{
                        pre.push(cur.viewNum);
                        return pre;
                    },[]);
                    // 整理投递人数数据
                    const sendNumData=this.myRecruitmentList.reduce((pre,cur)=>{
                        pre.push(cur.sendNum);
                        return pre;
                    },[]);
                    // 给echarts赋值
                    const {chartOption}=this;
                    chartOption.yAxis[0].data=axisData;
                    chartOption.yAxis[1].data=axisData;
                    // 查看人数赋值
                    chartOption.series[0].data=viewNumData;
                    // 投递人数赋值
                    chartOption.series[1].data=sendNumData;

                    // 开发临时写 测试一条数据
                    // chartOption.yAxis[0].data=["java"];
                    // chartOption.yAxis[1].data=["java"];
                    // chartOption.series[0].data=[1];
                    // chartOption.series[1].data=[1];
                    // this.myRecruitmentList=[1];

                    // 页面下一次渲染完成挂载echarts
                    this.$nextTick(()=>{
                        this.chart=this.$echarts.init(this.$refs.echarts);
                        this.chart.setOption(this.chartOption);
                    });
                }).catch(e=>{
                    console.error("获取我的招聘数据出错",e);
                });
            },
            // 点击快捷入口
            fastEntry(path){
                if(path){
                    this.$router.push({
                        path
                    });
                }else{
                    this.$message.info("功能暂未开放！");
                }
            }
        },
        created(){
            this.initData();
        },
    }
</script>

<style scoped lang="less">
    .pageContol{
        // 滚动条整体部分
        ::-webkit-scrollbar {
            width:0px;
            border-radius: 0px;
        }
        // 内层轨道 
        ::-webkit-scrollbar-track-piece{
            border-radius: 0px;
            background: white;
        }
        // 滚的条
        ::-webkit-scrollbar-thumb {
            border-radius: 0px;
            background-color: #e7e7e7;
        }
    }
    // 修改颜色 
    .framePage-body{
        background-color: #F5F5F6;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .block-item{
            flex-shrink: 0;
            height: 48.7%;
            width: 49.7%;
            background-color: white;
            padding: 1.875rem;
            .empty{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        // 板块1
        .block-item1{
            padding-left: 1.25rem;
            .title{
                font-size: 1.125rem;
                font-weight: 600;
                color: #333333;
                padding-left: .625rem;
            }
            .echatrs{
                width: 100%;
                height: calc(100% - 1.875rem);
                margin-top: 1.875rem;
                overflow: hidden;
            }
        }
        // 板块2
        .block-item2{
            .top{
                display: flex;
                justify-content: space-between;
                .title{
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: #333333;
                }
                .more{
                    font-size: .875rem;
                    color: #2878FF;
                    cursor: pointer;
                }
            }
            .list{
                margin-top: 10px;
                overflow-y: auto;
                height: calc(100% - 1.875rem);
                .list-item{
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #F5F5F5;
                    padding: 1rem 0;
                    .list-item-left{
                        .list-item-top{
                            display: flex;
                            .title{
                                font-size: .875rem;
                                font-weight: 600;
                                color: #333333;
                            }
                            .state{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 3.25rem;
                                height: 1.25rem;
                                border-radius: 10px;
                                font-size: .75rem;
                                margin-left: 10px;
                            }
                        }
                        .detail{
                            margin-top: 10px;
                            font-size: .75rem;
                            color: #666666;
                            display: flex;
                            .total{
                                margin-left: 20px;
                            }
                            span{
                                color: #2878FF;
                            }
                        }
                    }
                }
            }
        }
        // 板块3
        .block-item3{
            background-color: #F5F5F6;
            padding: 1.875rem 0;
            .title{
                font-size: 1.125rem;
                font-weight: 600;
                color: #333333;
                padding-left: 1.875rem;
            }
            .list{
                display: flex;
                flex-wrap: wrap;
                margin-top: 1.875rem;
                .list-item{
                    width: 20%;
                    border-right: 1px solid #F5F5F5;
                    border-bottom: 1px solid #F5F5F5;
                    height: 6.75rem;
                    background-color: white;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .img-wrap{
                        width: 2.625rem;
                        height: 2.625rem;
                        cursor: pointer;
                        img{
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }
                    .subtitle{
                        font-size: .875rem;
                        font-weight: 600;
                        color: #333333;
                        margin-top: 10px;
                    }
                }
            }
        }
        // 板块4
        .block-item4{
            .title{
                font-size: 1.125rem;
                font-weight: 600;
                color: #333333;
            }
            .list{
                overflow-y: auto;
                height: calc(100% - 1.875rem);
                margin-top: 10px;
                .list-item{
                    cursor: pointer;
                    border-bottom: 1px solid #F5F5F5;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1.25rem 0;
                    .className{
                        font-size: .875rem;
                        color: #333333;
                    }
                    .date{
                        font-size: .75rem;
                        color: #999999;
                    }
                }
            }
        }
    }
</style>